import { css } from '@emotion/react'

const style = {
  box: css`
    padding: 2px 0.25rem;

    @media (min-width: 768px) {
      padding: 2px 0.5rem;
    }
  `,
}

export default style
