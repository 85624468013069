import { FormattedMessage } from 'react-intl'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/device-shelf'

import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const DeviceShelf = ({ devices, viewAll, tagDiscount, ...props }) => {
  let sliderOptions = generateSliderOptions(240)

  return (
    <div {...props}>
      <Container className="position-relative">
        <Slider options={sliderOptions}>
          {devices.map(device => (
            <div key={device.id} css={style.box}>
              <Card device={device} tagDiscount={tagDiscount} />
            </div>
          ))}
        </Slider>
      </Container>
      {viewAll && (
        <Container className="d-flex justify-content-center mt-3">
          <DataLayer
            selector="DeviceShelf.viewAllButton"
            triggers={['click']}
            render={ref => (
              <Button ref={ref} variant="outlined" link={viewAll.link}>
                <FormattedMessage id="viewAll" defaultMessage="View All" />
              </Button>
            )}
          />
        </Container>
      )}
    </div>
  )
}

export default DeviceShelf
