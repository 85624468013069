import usePageDataLayer from '../use-page-data-layer'

const handlePageMessage = ({ selector, context, trigger }, pushMessage) => {
  if (/^TopBanner\/Carousel.banner#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id,
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 0,
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'none',
            item_variant: 'none',
            price: 'none',
            quantity: 'none',
            promotion_name: context.banner.name,
            promotion_id: context.banner.name,
            creative_name: context.banner.name,
            creative_slot: 'top_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }

  if (/^ShortcutMenu\/\[MenuItem\]#\d+$/i.test(selector)) {
    pushMessage({
      event: 'shortcut_menu',
      page_group: 'tmh_postpaid',
      shortcut_menu: context.item.name,
    })
  }

  if (/^MobilePackage\/\[PostpaidCard\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.priceplan.normalPrice) -
      parseFloat(context.pack.priceplan.sellingPrice)
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.mobilePostpaidPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: parseFloat(context.pack.priceplan.sellingPrice),
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[PostpaidCard.button#1\]#.+$/i.test(selector)) {
    let discount =
      parseFloat(context.pack.priceplan.normalPrice) -
      parseFloat(context.pack.priceplan.sellingPrice)

    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid',
      button: context.pack.priceplan.buttonName1,
      item_id: context.pack.id,
      item_name: context.pack.name,
      coupon_name: 'none',
      discount: isNaN(discount) ? 'none' : discount,
      index: context.mobilePostpaidPackages[0].packages.findIndex(
        pack => pack.id === context.pack.id
      ),
      item_brand: 'none',
      item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
      item_list_id: 'none',
      item_list_name: 'แพ็กเกจรายเดือน',
      item_variant: 'none',
      price: parseFloat(context.pack.priceplan.sellingPrice),
    })
  }

  if (/^MobilePackage\/\[PostpaidCard.button#2\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobilePostpaidPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'แพ็กเกจรายเดือน',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackageType\/\[PackageTypeCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.packageType.id,
            item_name: context.packageType.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.packageTypes.findIndex(
              pack => pack.id === context.packageType.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'แพ็กเกจหลัก',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackageType\/\[PackageTypeCard.button#1\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'card_more_detail',
      page_group: 'tmh_postpaid',
      button: context.packageType.buttonName1,
      item_id: context.packageType.id,
      item_name: context.packageType.name,
      coupon_name: 'none',
      discount: 'none',
      index: context.packageTypes.findIndex(
        pack => pack.id === context.packageType.id
      ),
      item_brand: 'none',
      item_category: 'package',
      item_list_id: 'truemoveh_package_type',
      item_list_name: context.header.name,
      item_variant: 'none',
      price: 'none',
    })
  }

  if (/^MobilePackageType\/\[PackageTypeCard.button#2\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.packageType.id,
            item_name: context.packageType.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.packageTypes.findIndex(
              pack => pack.id === context.packageType.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'แพ็กเกจหลัก',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[ExtraCard\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobileExtraPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Extra package',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'แพ็กเกจเสริม',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^MobilePackage\/\[ExtraCard.button\]#.+$/i.test(selector)) {
    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.pack.id,
            item_name: context.pack.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.mobileExtraPackages[0].packages.findIndex(
              pack => pack.id === context.pack.id
            ),
            item_brand: 'none',
            item_category: '1_Telecomunication (Telco)|Mobile & Gadgets',
            item_category2: 'SIM|Post-Paid',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^HighlightBanner\/Grid.banner#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_item', view: 'view_item_list' }[trigger],
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id,
            item_name: context.banner.name,
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: context.highlightBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'truemoveh_highlight_banner',
            item_list_name: context.header.name,
            item_variant: 'none',
            price: 'none',
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/\[Card\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'view_item_list',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.deviceManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'device',
            item_category2: 'smartphone_and_tablet',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'มือถือและแท็บเล็ต',
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/\[Card.button\]#\d+$/i.test(selector)) {
    let price = parseFloat(context.device.priceMin)
    let discount = parseFloat(context.device.discount)

    pushMessage({
      event: 'select_item',
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.device.id,
            item_name: context.device.name,
            coupon: 'none',
            currency: 'THB',
            discount: isNaN(discount) ? 'none' : discount,
            index: context.deviceManagements.findIndex(
              device => device.id === context.device.id
            ),
            item_brand: 'none',
            item_category: 'device',
            item_category2: 'smartphone_and_tablet',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'truemoveh_smartphone_and_tablet',
            item_list_name: 'มือถือและแท็บเล็ต',
            item_variant: 'none',
            price: isNaN(price) ? 'none' : price,
            quantity: 1,
          },
        ],
      },
    })
  }

  if (/^DeviceShelf\/DeviceShelf.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid',
      button: 'view_all',
      section_name: 'มือถือและแท็บเล็ต',
    })
  }

  if (/^News\/\[Card\]#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_content' }[trigger],
      page_group: 'trueonline_tmh_postpaid',
      content_name: context.article.title,
      section_name: context.header.name,
      content_type: 'news',
    })
  }

  if (/^News\/\News.viewCard#\d+$/i.test(selector)) {
    pushMessage({
      event: { view: 'view_content' }[trigger],
      page_group: 'trueonline_tmh_postpaid',
      content_name: context.title,
      section_name: context.header.name,
      content_type: 'news',
    })
  }

  if (/^News\/News.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid',
      button: 'view_all',
      section_name: context.header.name,
    })
  }

  if (selector === 'BottomBanner/Banner') {
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: 'tmh_postpaid',
      ecommerce: {
        items: [
          {
            item_id: context.banner.id,
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: 'none',
            item_list_name: 'bottom_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'bottom_banner',
            location_id: 0,
          },
        ],
      },
    })
  }

  if (/^Faq\/\[Card\]#\d+$/i.test(selector)) {
    pushMessage({
      event: { click: 'select_content', view: 'view_content' }[trigger],
      page_group: 'trueonline_tmh_postpaid',
      content_name: context.topic.name,
      section_name: context.header.name,
      content_type: 'faq',
    })
  }

  if (/^Faq\/Faq.viewAllButton$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: 'tmh_postpaid',
      button: 'view_all',
      section_name: context.header.name,
    })
  }
}

const useTruemovehPostpaidPageDataLayer = () => {
  let { createHandler, createScopeProps } = usePageDataLayer()
  let handleMessage = createHandler(handlePageMessage)
  return { handleMessage, createScopeProps }
}

export default useTruemovehPostpaidPageDataLayer
